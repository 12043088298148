<template>
  <div class="me">
    <!-- 顶部区域 -->
    <div class="me-top flex-center">
      <div class="top-content flex-center">
        <div class="top-box flex-center">
          <div class="top-portrait">
            <img :src="user.avatarUrl" alt="" />
          </div>
          <div class="top-msg">
            <div class="top-name">{{ user.realName|ellipsis }}</div>
            <div class="top-phone flex-center">
              <img src="../../assets/images/me-sj.png" alt="" />
              <span>{{ user.mobile | phoneFilter }}</span>
            </div>
          </div>
        </div>
        <div class="top-btn" @click="goRevise">
          <img src="../../assets/images/me-bj.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 顶部浮动模块 -->
    <div class="power" v-if="user.identity == 1">
      <div class="power-top">
        <van-cell :title="'物业（'+user.mchName+'）'" is-link to="/my/property" />
      </div>
      <div class="power-box">
        <van-grid :column-num="3" :border="false">
          <van-grid-item v-for="item in powerList" v-if="item.show == 1" :key="item.id" :icon="item.imgUrl" :text="item.text" :badge="item.badge" :to="item.path" />
        </van-grid>
      </div>
    </div>
    <!-- 导航模块 -->
    <div class="nav">
      <div class="nav-box">
        <van-cell v-for="item in navList" v-if="item.show == 1" :key="item.id" :title="item.text" :icon="item.imgUrl" is-link :to="item.path" />
      </div>
    </div>
    <!-- 底部导航 -->
    <bottom-nav></bottom-nav>
  </div>
</template>
<script>
import bottomNav from "../../components/bottomNav.vue";

export default {
  name: "Index",
  data() {
    return {
      user: {},
      powerList: [
        {
          id: 0,
          text: "我的二维码",
          imgUrl: require("../../assets/images/power01.png"),
          path: "/my/barcode",
          show: 1,
        },
        {
          id: 1,
          text: "待带看房",
          imgUrl: require("../../assets/images/power02.png"),
          badge: "",
          path: "/my/followUp",
          show: 0,
        },
        {
          id: 2,
          text: "房源收件",
          imgUrl: require("../../assets/images/power03.png"),
          badge: "",
          path: "/my/listingReceipt",
          show: 0,
        },
      ],
      navList: [
        {
          id: 0,
          text: "我的房源",
          imgUrl: require("../../assets/images/me-nav01.png"),
          path: "/my/resources",
          show: 1,
        },
        {
          id: 1,
          text: "我的收藏",
          imgUrl: require("../../assets/images/me-nav02.png"),
          path: "/my/collect",
          show: 1,
        },
        {
          id: 2,
          text: "我的预约",
          imgUrl: require("../../assets/images/me-nav03.png"),
          path: "/my/order",
          show: 1,
        },
        {
          id: 3,
          text: "物业认证",
          imgUrl: require("../../assets/images/icon_certification@2x.png"),
          path: "/my/bindMch",
          show: 1,
        },
        {
          id: 4,
          text: "房贷计算器",
          imgUrl: require("../../assets/images/me-nav04.png"),
          path: "/my/counter",
          show: 1,
        },
        // {
        //   id: 5,
        //   text: "实名认证",
        //   imgUrl: require("../../assets/images/me-nav06.png"),
        //   path: "/my/MyCertification",
        //   show: 1,
        // },
        // {
        //   id: 6,
        //   text: "贷款进度",
        //   imgUrl: require("../../assets/images/me-nav07.png"),
        //   path: "/my/LoanProgressQuery",
        //   show: 1,
        // },
        {
          id: 7,
          text: "房产评估",
          imgUrl: require("../../assets/images/icon_assessment@2x.png"),
          path: "/my/houseAssessment",
          show: 1,
        },
      ],
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 10) {
        return value.slice(0, 10) + '...'
      }
      return value
    }
  },
  created() {
    //  this.getToken();
  },
  mounted() {
    //----------测试数据，投产环境里删除此行---------------
    // window.localStorage.setItem("token", "ihzzm2bl543m63v6cdrktki9dqnphfqr");
    //----------测试数据，结束---------------
    if (this.isLogin()) {
      this.getUserInfo();
    }
  },
  methods: {
    // 跳转到修改信息页面
    goRevise() {
      this.$router.push("/my/revise");
    },
   
    getUserInfo() {
      this.$post("user/getUserInfo", { type: 1 }).then((res) => {
        console.info(res)
        if (res.status == 200) {
          this.user = res.data.userInfo;
          if (!res.data.userInfo.realName) {
            this.user.realName = res.data.userInfo.nickname;
          }

          if (this.user.identity == 1) {
            this.navList[3].show = 0;
          }
          //物业机构信息
          var mchInfo = res.data.mchInfo;
          this.powerList[1].show = mchInfo.housePermission;
          if (mchInfo.houseCount > 0) {
            console.log(mchInfo.houseCount)
            this.powerList[2].badge = mchInfo.houseCount;
          }
          this.powerList[2].show = mchInfo.userPermission;
          if (mchInfo.userCount > 0) {
            this.powerList[1].badge = mchInfo.userCount;
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
  components: {
    bottomNav,
  },
};
</script>
<style lang="less" scoped>
.me {
  padding-bottom: 100px;
  background-color: #fff;
  min-height: 100vh;
  box-sizing: border-box;
  .me-top {
    width: 100%;
    height: 150px;
    background: url(../../assets/images/me-bg.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 0 20px;
    box-sizing: border-box;
    .top-content {
      justify-content: space-between;
      width: 100%;
      .top-box {
        .top-portrait {
          width: 76px;
          height: 76px;
          border-radius: 50%;
          border: 3px solid rgba(255, 255, 255, 0.3);
          box-sizing: border-box;
          margin-right: 10px;
          img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
          }
        }
        .top-msg {
          .top-name {
            color: #fff;
            font-size: 18px;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .top-phone {
            img {
              width: 13px;
              height: 13px;
              margin-right: 5px;
            }
            span {
              color: #fff;
              font-size: 13px;
              line-height: 1;
            }
          }
        }
      }
      .top-btn {
        padding-left: 15px;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .power {
    margin: 0 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    position: relative;
    top: -20px;
    overflow: hidden;
    background-color: #fff;
    padding-bottom: 5px;
    .power-top {
      padding: 0 20px;

      .van-cell {
        border-bottom: 1px solid rgb(239, 239, 239);
        padding: 10px 0;
        span {
          color: #666;
          font-size: 16px;
        }
      }
    }
    .power-box {
      /deep/.van-grid-item__icon {
        font-size: 20px;
      }

      /deep/.van-info {
        top: 5px;
        right: -5px;
      }
      /deep/.van-grid-item__text {
        font-size: 16px;
        color: #555;
      }
    }
  }
  .nav {
    padding: 0 20px;
    .van-cell {
      padding: 15px 0;
      border-bottom: 1px solid rgb(237, 237, 237);
      span {
        font-size: 16px;
        color: #555;
        margin-left: 10px;
      }
      .van-cell__left-icon {
        font-size: 24px;
        img {
          vertical-align: baseline;
        }
      }
    }
  }
}
</style>